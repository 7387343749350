import L from 'leaflet';

$(() => {
    var $mapContainer = $('#ContactMap');

    if ($mapContainer.length) {
        let offices = $mapContainer.data('offices');
        let agencies = $mapContainer.data('agencies');

        let map = L.map('ContactMap').setView([51.505, -0.09], 7);

        L.tileLayer(
            'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
            {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                id: 'ettic-connect/clewndsim00no01lk3yq52nmd',
                accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
            }
        ).addTo(map);

        if (offices.length || agencies.length) {
            map.fitBounds(
                offices
                    .map((office) => [office.address.latitude, office.address.longitude])
                    .concat(
                        agencies
                            .map((agency) => [agency.address.latitude, agency.address.longitude])
                    ),
                    { maxZoom: 7 }
            );
        }

        let officeIcon = L.divIcon({
            html: '<i class="fa-solid fa-map-marker-alt"></i>',
            iconSize: [40, 40],
            className: 'color-caribe h1',
        });

        let agencyIcon = L.icon({
            iconUrl: new URL('../../images/favicon-32x32.png', import.meta.url),
            iconSize: [32, 32],
        });

        offices.forEach(function (office) {
            let marker = L.marker(
                [office.address.latitude, office.address.longitude],
                {
                    icon: officeIcon,
                }
            ).addTo(map);

            marker.bindPopup(
                `<div>
                    <h3>${office.name}</h3>
                    <ul class="list-unstyled">
                        <li><i class="fa-solid fa-marker color-caribe"></i> ${office.address.full_address}</li>
                        <li><i class="fa-solid fa-user-tie color-caribe"></i> ${office.contact_name}</li>
                        <li><i class="fa-solid fa-phone color-caribe"></i> ${office.phone.formatted}</li>
                        <li><a href="mailto:${office.contact_email}"><i class="fa-solid fa-envelope color-caribe"></i> ${office.contact_email}</a></li>
                    </ul>
                </div>`
            );
            marker.on('mouseover', (e) => marker.openPopup());
        });

        agencies.forEach(function (agency) {
            let marker = L.marker(
                [agency.address.latitude, agency.address.longitude],
                {
                    icon: agencyIcon,
                }
            ).addTo(map);

            marker.bindPopup(
                `<div>
                    <h3>${agency.name}</h3>
                    <ul class="list-unstyled">
                        <li><i class="fa-solid fa-marker color-caribe"></i> ${agency.address.full_address}</li>
                        <li><i class="fa-solid fa-user-tie color-caribe"></i> ${agency.contact}</li>
                        <li><i class="fa-solid fa-phone color-caribe"></i> ${agency.phone.formatted}</li>
                        <li><a href="mailto:${agency.email}"><i class="fa-solid fa-envelope color-caribe"></i> ${agency.email}</a></li>
                    </ul>
                </div>`
            );
            marker.on('mouseover', (e) => marker.openPopup());
        });
    }
});
